import { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { ApiRequest, ShowLoader } from 'GlobalFunctions';
import getOrderDetails from '../Orders/getOrderDetails';
import Loader from 'utils/Loader';
import StripeForm from './Utils/StripeForm';
import Shipping from './Utils/Shipping';
import ClientForm from './Utils/ClientForm';
import { enqueueSnackbar, useSnackbar } from "notistack";
import DefaultForm from './Utils/DefaultForm';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import { ProjectConfig } from 'Global';
import POForm from './Utils/POForm';

export default function Checkout() {
    const [payment_info, setPaymentInfo] = useState<any>({});
    const [orderDetails, setOrderDetails] = useState<any>({});
    
    const [shippingCompleted, setShippingCompleted] = useState(false);

    const [order_id, setOrderId] = useState<string | null>(null);
    
    
    useEffect(() => {

        /*=========================================================
        CHECK IF ORDER ID EXISTS
        =========================================================*/
        
        

        const orderStatus = document.querySelector('[pc-step="status"]');
        orderStatus?.classList.add('hidden');

        //config order-status-back-button 
        const orderStatusBackButton = document.querySelector('[pc="status-back"]');
        orderStatusBackButton?.addEventListener("click", () => {
            window.location.href = '/';
        });

        /*=========================================================
        GET ORDER DETAILS
        =========================================================*/
        getOrderDetails({
            page: "checkout",
            callback: async (cart: any) => {
                setOrderDetails(cart);
                getPaymentForm();
            }
        });
        

        

        const paymentForm = document.querySelector('[pc="payment-form"]');
        
        if (paymentForm) {
            const loaderContainer = document.createElement('div');
            const root = createRoot(loaderContainer);
            root.render(<Loader />);
            paymentForm.replaceChildren(loaderContainer);
        }

        // Set up the accordion functionality
        setUpAccordion();

    }, []);


    /*=========================================================
    SET PAYMENT STATUS
    =========================================================*/
    const setPaymentStatus = (status: any, message: string) => {
        enqueueSnackbar(message, { variant: status });

        if(status === 'success') {
            
            const elements = document.querySelectorAll('[pc-hide=""]');
            elements.forEach(element => {
                element.classList.add('hidden');
            });

            //change order-status-title to "Order # ${order_id} completed"
            const orderStatusTitle = document.querySelector('[pc="status-heading"]');
            if(orderStatusTitle) {
                orderStatusTitle.textContent = `Order # ${localStorage.getItem('order_id')} completed`;
            }
            
            const orderStatus = document.querySelector('[pc-step="status"]');
            orderStatus?.classList.remove('hidden');

            /*DELETE LOCALSTORAGE*/
            localStorage.removeItem('order_id');
        }
    }

    /*=========================================================
    STRIPE FORM
    =========================================================*/
    useEffect(() => {
        console.log("Payment info*********************", payment_info);
        if (Object.keys(payment_info).length !== 0) {
            const paymentForm = document.querySelector('[pc="payment-form"]');
            if (paymentForm) {

                const root = createRoot(paymentForm);
                root.render(
                    <>
                        {orderDetails.purchaseOrdersLogic? (
                            <POForm
                                payment_method={"purchase_order"}
                                setPaymentStatus={setPaymentStatus}
                                enqueueSnackbar={enqueueSnackbar}
                                ShowLoader={ShowLoader}
                            />
                        ) : (
                            <>
                            {payment_info.payment_method === 'stripe' && (
                            <StripeForm 
                                payment_settings={payment_info.payment_settings} 
                                setPaymentStatus={setPaymentStatus}
                            />
                            )}

                            {(
                                payment_info.payment_method === 'elavon' || 
                                payment_info.payment_method === 'authorize_net' ||
                                payment_info.payment_method === 'test_mode'
                            ) && (
                                <DefaultForm
                                    payment_method={payment_info.payment_method}
                                    setPaymentStatus={setPaymentStatus}
                                    enqueueSnackbar={enqueueSnackbar}
                                    ShowLoader={ShowLoader}
                                />
                            )}
                            

                            {payment_info.payment_method === 'square' &&
                                <PaymentForm 
                                    applicationId = {ProjectConfig.SQUARE_CLIENT_ID}
                                    cardTokenizeResponseReceived={async (token, verifiedBuyer) => {
                                        ShowLoader(true)

                                        await ApiRequest({
                                            url: "/payments/defaultCheckout",
                                            method: "post",
                                            body: {
                                                order_id: localStorage.getItem("order_id"),
                                                sourceId: token.token,
                                                payment_method: payment_info.payment_method
                                            },
                                            setResponse: (response: any) => {
                                                if(response.status === "success") {
                                                    setPaymentStatus('success', "Payment successful");
                                                }
                                                else {
                                                    setPaymentStatus('error', "Payment failed");    
                                                }
                                            }
                                        });
                                        ShowLoader(false)


                                    } }
                                    locationId='LBB7R4DKKTWBG'
                                >
                                    <CreditCard />
                                </PaymentForm>
                            }
                            </>
                        )}
                    </>
                );
            }
        }
    }, [payment_info]);

    const getPaymentForm = async () => {
        ShowLoader(true);
        await ApiRequest({
            url: "/payments/getPaymentForm",
            method: "get",
            query: {
                order_id: localStorage.getItem('order_id'),
            },
            setResponse: async (response: any) => {
                if(response.status==='error') {
                    enqueueSnackbar(response.message, { variant: 'error' });
                    return
                }
                
                setPaymentInfo(response);

                
            },
        });
    };

    const setUpAccordion = () => {
        const accordionItems = document.querySelectorAll('[pc-acc="item"]');
    
        // HIDE ALL EXCEPT THE FIRST
        accordionItems.forEach((item, index) => {
            const title = item.querySelector('[pc="accordion-heading"]') as HTMLElement;
            if (index !== 0) {
                Array.from(item.children).forEach(child => {
                    if (child !== title && child instanceof HTMLElement) {
                        child.style.display = 'none';
                    }
                });
                // title?.classList.add('disabled');
            } else {
                title?.classList.add('active');
            }
        });
    
        // CLICK EVENT FOR ACCORDION TITLE
        document.querySelectorAll('[pc="accordion-heading"]').forEach(title => {
            // Check if the title has the 'disabled' class to prevent click
            if (!title.classList.contains('disabled')) {
                title.addEventListener('click', event => {
                    const clickedTitle = event.currentTarget as HTMLElement;
                    const accordionItem = clickedTitle.closest('[pc-acc="item"]') as HTMLElement;
    
                    // Toggle visibility
                    if (clickedTitle.classList.contains('active')) {
                        clickedTitle.classList.remove('active');
                        Array.from(accordionItem.children).forEach(child => {
                            if (child !== clickedTitle && child instanceof HTMLElement) {
                                child.style.display = 'none';
                            }
                        });
                    } else {
                        clickedTitle.classList.add('active');
                        Array.from(accordionItem.children).forEach(child => {
                            if (child instanceof HTMLElement) {
                                child.style.display = 'block';
                            }
                        });
                    }
    
                    // Hide all siblings of the other accordion items
                    accordionItems.forEach(item => {
                        if (item !== accordionItem) {
                            const otherTitle = item.querySelector('[pc="accordion-heading"]') as HTMLElement;
                            otherTitle?.classList.remove('active');
                            Array.from(item.children).forEach(child => {
                                if (child !== otherTitle && child instanceof HTMLElement) {
                                    child.style.display = 'none';
                                }
                            });
                        }
                    });
                });
            }
        });
    
        // CLICK EVENT FOR ACCORDION BUTTON
        document.querySelectorAll('[pc-step="accordion-next"]').forEach(button => {
            button.addEventListener('click', event => {
                const accordionItem = (event.currentTarget as HTMLElement).closest('[pc-acc="item"]') as HTMLElement;
                const title = accordionItem.querySelector('[pc="accordion-heading"]') as HTMLElement;
    
                // Hide current item
                Array.from(accordionItem.children).forEach(child => {
                    if (child !== title && child instanceof HTMLElement) {
                        child.style.display = 'none';
                    }
                });
                title?.classList.remove('active');
    
                // Show the next item
                const nextAccordionItem = accordionItem.nextElementSibling as HTMLElement;
                if (nextAccordionItem) {
                    Array.from(nextAccordionItem.children).forEach(child => {
                        if (child instanceof HTMLElement) {
                            child.style.display = 'block';
                        }
                    });
                    nextAccordionItem.querySelector('[pc="accordion-heading"]')?.classList.add('active');
                }
            });
        });
    };


    const [allFieldsFilled, setAllFieldsFilled] = useState(false);

    const isFormComplete = () => {
        const elements = document.querySelectorAll('input[pc], select[pc]');
        let incompleteFields: string[] = [];
        elements.forEach((element) => {
            const dataPc = element.getAttribute('pc');
            if (dataPc && [
                'fname', 
                'lname', 
                'address', 
                'address2', 
                'postal', 
                'city',
                'country', 
                'state', 
                's-fname', 
                's-lname', 
                's-address', 
                's-address2', 
                's-postal', 
                's-city', 
                's-country', 
                's-state',
                'email', 
                'phone', 
            ].includes(dataPc)) {
                if (!(element as HTMLInputElement).value) {
                    incompleteFields.push(dataPc);
                }
            }
        });
    
        const sameShippingBilling = document.querySelector('input[pc="same-as"]')  as HTMLInputElement
    
        if (sameShippingBilling && sameShippingBilling.checked) {
            //exclude shipping fields
            incompleteFields = incompleteFields.filter(field => ![
                's-fname', 
                's-lname', 
                's-address',
                's-address2',
                's-postal',
                's-city',
                's-country',
                's-state',
            ].includes(field));
        }
    
    
        /*=========================================================
        CHECK check_terms CHECKBOX
        =========================================================*/
        const checkTerms = document.querySelector('input[pc="terms"]') as HTMLInputElement;
        if (checkTerms && !checkTerms.checked) {
            
            incompleteFields.push('check_terms');
        }   
        
        /*=========================================================
        CHECK SHIPPING METHOD (shipping-radio)
        =========================================================*/
        const shippingRadio = document.querySelector('input[pc="shipping-radio"]:checked') as HTMLInputElement;
        if (!shippingRadio) {
            incompleteFields.push('Delivery Method');
        }


        setAllFieldsFilled(incompleteFields.length === 0);

        console.log(incompleteFields);
    
        
        return {
            incompleteFields
        };
    };
    


    useEffect(() => {
        enableDisableAccordion(allFieldsFilled);

        
    }, [allFieldsFilled]);


    const enableDisableAccordion = (allFieldsFilled: boolean) => {
        const accordion = document.querySelector('[pc-step="payment"]');
        if (accordion) {
            const title = accordion.querySelector('[pc="accordion-heading"]') as HTMLElement;
            const button = document.querySelector('[pc-step="accordion-next"]') as HTMLElement;
            if (title && button) {
                if (allFieldsFilled) {
                    title.classList.remove('disabled');
                    button.classList.remove('is-disabled');
                }
                else {
                    title.classList.add('disabled');
                    button.classList.add('is-disabled');
                }
            }
        }
    }

    

    return (
        <>
            
            <ClientForm
                orderDetails={orderDetails} 
                isFormComplete={isFormComplete} 
            />
            <Shipping 
                active_shipping_id={orderDetails.shipping_id}
                setShippingCompleted={setShippingCompleted}
                isFormComplete={isFormComplete}
            />
            
        </>
    )
}
